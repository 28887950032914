import request from "@/api/request";

// 获取banner列表
export const bannerList = () => request.get('/v2/pc/banner/list')

// 菜单模块
export const moduleList = () => request.get('/v2/pc/module/list')

// 用户反馈
export const userComment = (data) => request.post('/v2/user/comment', data)

// 用户行为记录
export const actionLog = (data) => request.post('/v2/action-log', data)

