import { actionLog } from "@/api";
import { getLocalToken, getSessToken } from "@/common/auth";

const state = {
  loginVisible: false,//登录弹窗
  dialogBalanceVisible: false,
  userInfo: {},
  toPath: null,
  exitType: false,
  showDownloadApp: false,
  dialogVisibleGuide: false,//引导
  activeGuide: 0,
  isFirst: localStorage.getItem('isFirst'),//是否第一次登录
  nickName: localStorage.getItem("nickName")
}
const getters = {}
const mutations = {
  // 设置登录弹窗显示
  SET_LOGINVISIBLE: (state, data) => {
    state.loginVisible = data
  },
  SET_TO_PATH: (state, data) => {
    state.toPath = data
  },
  SET_USERINFO: (state, data) => {
    state.userInfo = data
    state.nickName = data.nickName || ''
    localStorage.setItem("nickName", data.nickName || '')
  },
  SET_BALANCE_DIALOG_VISIBLE: (state, data) => {
    state.dialogBalanceVisible = data
  },
  SET_DOWNLOAD_APP: (state, data) => {
    state.showDownloadApp = data
  },
  // 设置引导弹窗显示
  SET_GUIDEVISIBLE: (state, data) => {
    state.dialogVisibleGuide = data
  },
  // 设置引导
  SET_GUIDEACTIVE: (state, data) => {
    state.activeGuide = data
  },
  //设置是否第一次登录
  SET_ISFIRST: (state, data) => {
    state.isFirst = data
    localStorage.setItem("isFirst", data);
  }
}
const actions = {
  setLoginVidible({ commit }, data) {
    commit('SET_LOGINVISIBLE', data)
  },
  setDialogBalanceVisible({ commit }, data) {
    commit('SET_BALANCE_DIALOG_VISIBLE', data)
  },
  // 设置前往路由
  setToPath({ commit }, data) {
    commit('SET_TO_PATH', data)
  },
  // 设置个人信息
  setUserInfo({ commit }, data) {
    commit('SET_USERINFO', data)
  },
  setDownloadApp({ commit }, data) {
    commit('SET_DOWNLOAD_APP', data)
  },
  // 用户行为记录
  actionLog({ commit }, data) {
    let param = {
      actionKey: data.actionKey,
      channel: 'PC',
      token: getLocalToken() || getSessToken(),
      extension: data.extension || null
    }
    actionLog(param).then(() => {
    })
  },
  // 引导弹窗
  setGuideVisible({ commit }, data) {
    commit('SET_GUIDEVISIBLE', data)
  },
  setGuideActive({ commit }, data) {
    commit('SET_GUIDEACTIVE', data)
  },
  setIsFirst({ commit }, data) {
    commit('SET_ISFIRST', data)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

