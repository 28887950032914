import axios from 'axios'
import { Loading, Message } from "element-ui";
import { exitLogin, getLocalToken, getSessToken } from "@/common/auth";
import store from '@/store';
import router from "@/router";

export const SUCCESS_CODE = 0;

// 默认地址
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// if (process.env.NODE_ENV === "development") {
//   // 测试环境的域名
//   axios.defaults.baseURL = '/'
// } else {
//   // 生产环境的域名
//   axios.defaults.baseURL = 'https://hwz.weizhudata.com/'
// }
// axios.defaults.timeout = 20000

// 请求拦截器
axios.interceptors.request.use((config) => {
  config.headers['channel'] = 'pc'
  let token = getLocalToken() || getSessToken()
  if (token) {
    config.headers['X-CSRFToken'] = token
  }
  // if (process.env.NODE_ENV === "development") {
  //   config.headers['secret'] = "c137"
  //   config.headers['userId'] = "2"
  // }
  return config
}, (err) => {
  return Promise.reject(err)
})

// 响应拦截器
axios.interceptors.response.use((response) => {
  let res = response.data

  if (res.code === 1009) {
    // store.dispatch('setLoginVidible', true)
    exitLogin()
    // router.push('/')
    router.go(0)
  }

  if (res.code === 100 || res.code === 1016) {
    store.dispatch('setDialogBalanceVisible', true)
  }

  if (res.code !== 0 && res.code !== 1009 && res.code !== 100 && res.code !== 1016 && res.code !== 1010 && res.code !== 2002) {
    Message({
      message: res.tip || 'Error',
      type: 'error',
    })
    Loading.service().close()
    // router.push('/')
  }

  return response
}, (err) => {
  // if(err.response.data.code === 1019){
  //   this.$router.push('/');
  // }
  return Promise.reject(err)
})

const request = {
  get(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: data.data
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  post(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // {
  //   headers['Content-Type'] = 'multipart/form-data'
  // }
  postFile(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  delete(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.delete(url, {
        params: data,
      })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
}

export default request

