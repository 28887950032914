import store from "@/store";
import router from "@/router";
import resize from "swiper/src/modules/resize/resize";
// token
export const setLocalToken = (value, expired) => {
  let data = {value: value, expirse: new Date(expired).getTime()};
  localStorage.setItem('localToken', JSON.stringify(data));
}

export const getLocalToken = () => {
  let data = JSON.parse(localStorage.getItem('localToken'));
  if (data !== null) {
    if (data.expirse != null && data.expirse < new Date().getTime()) {
      localStorage.removeItem('localToken');
      // localStorage.clear();
    } else {
      return data.value;
    }
  }
  return null;
}

export const setSessToken = (value) => {
  sessionStorage.setItem('sessionToken', value);
}
export const getSessToken = () => {
  return sessionStorage.getItem('sessionToken');
}

// 设置用户电话号码
export const setUserPhone = (data) => {
  localStorage.setItem('userPhone', data)
}

// 退出登录
export const exitLogin = () => {
  localStorage.removeItem('localToken')
  sessionStorage.removeItem('sessionToken')
  localStorage.removeItem('userPhone')
  localStorage.removeItem('isFirst')
  localStorage.removeItem('nickName')
}
// 设置title
export const getPageTitle = (pageTitle) => {
  if (pageTitle) {
    return `${pageTitle}`
  }
  return '好微助'
}

// 设置跳转路由
export const setToLink = (path) => {
  let token = getLocalToken() || getSessToken()
  if (token) {
    router.push(path)
  } else {
    store.dispatch('setLoginVidible', true)
    store.dispatch('setToPath', path)
  }
}
// 倒计时
export const countDown = (seconds) => {
  let d = parseInt(seconds / (24 * 60 * 60))
  d = d < 10 ? "0" + d : d
  let h = parseInt(seconds / (60 * 60) % 24);
  h = h < 10 ? "0" + h : h
  let m = parseInt(seconds / 60 % 60);
  m = m < 10 ? "0" + m : m
  let s = parseInt(seconds % 60);
  s = s < 10 ? "0" + s : s
  return d + ' 天 ' + h + ' : ' + m + ' : ' + s
}



