import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/element-variables.scss'
import "animate.css"
import preventRepeatClick from "@/common/preventRepeatClick";

import VueRouter from 'vue-router'

Vue.use(VueRouter)
//复制剪贴板插件
Vue.use(VueClipboard)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  // 调用回调方法 让浏览器不再报出这个错误
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI);
Vue.use(preventRepeatClick)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
