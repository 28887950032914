import Vue from 'vue'
import VueRouter from 'vue-router'
import { getLocalToken, getPageTitle, getSessToken } from "@/common/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Index',
    component: () => import('../views/index/index.vue'),
    meta: { title: '好微助-首页' },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/home/Home.vue'),
        meta: { title: '好微助-首页', keepAlive: false }
      },
      {
        path: '/order',
        name: 'GrabOrder',
        component: () => import('../views/customerResource/grabOrder'),
        meta: { title: '好微助-抢单', refresh: false, keepAlive: true }
      },
      {
        path: '/order/detail',
        name: 'GrabOrderDetail',
        component: () => import('../views/customerResource/grabOrderDetail'),
        meta: { title: '好微助-抢单详情', needLogin: true }
      },
      {
        path: '/order/detailSvip',
        name: 'GrabOrderDetailSvip',
        component: () => import('../views/customerResource/grabOrderDetailSvip'),
        meta: { title: '好微助-抢单详情', needLogin: true }
      },
      {
        path: '/customer',
        name: 'Customer',
        component: () => import('../views/customerResource/myCustomer'),
        meta: { title: '好微助-购买记录', needLogin: true }
      },
      {
        path: '/customer/detail',
        name: 'CustomerDetail',
        component: () => import('../views/customerResource/myCustomerDetail'),
        meta: { title: '好微助-购买记录详情', needLogin: true, }
      },
      {
        path: '/enterprise',
        name: 'Enterprise',
        component: () => import('../views/findCustomers/enterprise'),
        meta: { title: '好微助-企业匹配产品' }
      },
      {
        path: '/enterprise/detail',
        name: 'EnterpriseDetail',
        component: () => import('../views/findCustomers/enterpriseDetail'),
        meta: { title: '好微助-企业匹配产品详情', needLogin: true }
      },
      {
        path: '/enterprise/info',
        name: 'EnterpriseInfo',
        component: () => import('../views/findCustomers/enterpriseDetailInfo'),
        meta: { title: '好微助-企业详情', needLogin: true }
      },
      {
        path: '/enterprise/report',
        name: 'EnterpriseReport',
        component: () => import('../views/findCustomers/EvaluationReport'),
        meta: { title: '好微助-评估报告', needLogin: true }
      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('../views/findCustomers/product'),
        meta: { title: '好微助-找客户' }
      },
      {
        path: '/product/detail',
        name: 'ProductDetail',
        component: () => import('../views/findCustomers/productDetail'),
        meta: { title: '好微助-找客户详情', needLogin: true }
      },
      {
        path: '/product/info',
        name: 'ProductInfo',
        component: () => import('../views/findCustomers/productInfo'),
        meta: { title: '好微助-产品详情', needLogin: true }
      },
      {
        path: '/findProduct',
        name: 'findProduct',
        component: () => import('../views/findProduct/product'),
        meta: { title: '好微助-产品库', needLogin: false, keepAlive: true }
      },
      {
        path: '/user',
        // name: 'User',
        component: () => import('../views/user/index'),
        meta: { title: '好微助-个人中心', needLogin: true },
        children: [
          {
            path: '/',
            name: 'UserInfo',
            component: () => import('../views/user/userInfo'),
            meta: { title: '好微助-个人信息', needLogin: true }
          },
          {
            path: '/user/customer',
            name: 'UserCustomer',
            component: () => import('../views/user/customer'),
            meta: { title: '好微助-我的抢单客户', needLogin: true }
          },
          {
            path: '/user/enterprise',
            name: 'UserEnterprise',
            component: () => import('../views/user/collectEnterprise'),
            meta: { title: '好微助-我的收藏-企业', needLogin: true }
          },
          {
            path: '/user/product',
            name: 'UserProduct',
            component: () => import('../views/user/collectProduct'),
            meta: { title: '好微助-我的收藏-产品', needLogin: true }
          },
          {
            path: '/user/connect',
            name: 'UserConnect',
            component: () => import('../views/user/connectCustomer'),
            meta: { title: '好微助-我的客户号码-联系方式', needLogin: true }
          },
          {
            path: '/user/export',
            name: 'UserExport',
            component: () => import('../views/user/connectNotExported'),
            meta: { title: '好微助-我的客户号码-未导出联系方式', needLogin: true }
          },
          {
            path: '/user/exported',
            name: 'UserExported',
            component: () => import('../views/user/connectExported'),
            meta: { title: '好微助-我的客户号码-已导出联系方式', needLogin: true }
          },
          {
            path: '/user/expenses',
            name: 'UserExpenses',
            component: () => import('../views/user/expensesRecord'),
            meta: { title: '好微助-消费记录', needLogin: true }
          },
        ]
      },
      {
        path: '/vip',
        name: 'Vip',
        component: () => import('../views/vip/vip'),
        meta: { title: '好微助-vip', needLogin: true }
      },
      {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('../views/info/userAgreement'),
        meta: { title: '好微助-用户协议' }
      },
      {
        path: '/policy',
        name: 'Policy',
        component: () => import('../views/info/protectionPolicy'),
        meta: { title: '好微助-个人信息保护政策' }
      },
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})
router.beforeEach((to, from, next) => {
  let token = getLocalToken() || getSessToken()
  if (token) {
    // document.title = getPageTitle(to.meta.title)
    console.log(to.query.redirect);
    if (to.query.redirect) {
      next(to.query.redirect)
    } else {
      next()
    }
  } else {
    if (to.meta.needLogin) {

      next('/')
    } else {
      // document.title = getPageTitle(to.meta.title)
      next()
    }
  }
})

export default router
