<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && isRouterAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
  </div>
</template>
<script>
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?96ca404f3126431b3c7350232d2731cd";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  created() {},
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>


<style lang="scss">
#app {
  height: 100vh;
}
@font-face {
  font-family: numFont;
  src: url("@/assets/css/fonts/font.ttf");
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif, Microsoft YaHei-Regular,
    Microsoft YaHei;
  font-size: $-font-14;
  font-weight: 400;
}

body {
  -webkit-text-size-adjust: none;
  background: $-bg;
  color: $-text-333;
}

[v-cloak] {
  display: none;
}

h3 {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $-text-333;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  font-size: $-font-14;
}

img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
}

i,
em {
  font-style: normal;
}

//::-webkit-scrollbar {
//  display: none;
//  /* Chrome Safari */
//}
//
//html {
//  scrollbar-width: none;
//  /* firefox */
//  -ms-overflow-style: none;
//  /* IE 10+ */
//}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}

.v-modal {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(2px);
}
</style>
